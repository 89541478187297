import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import { Role } from "../utils/userRoles";
import Login from "../components/Login.vue";
import intersection from "lodash/intersection";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: { name: "FlightplansForm" },
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/preferences",
      name: "Preferences",
      component: () => import("../components/Users/UserPreferences.vue"),
      meta: {
        authorize: [
          Role.FPManager,
          Role.FPViewer,
          Role.SiteManager,
          Role.UserManager,
        ],
      },
    },
    {
      path: "/profile",
      name: "Profile",
      component: () => import("../components/Users/UserProfile.vue"),
      meta: {
        authorize: [
          Role.FPManager,
          Role.FPViewer,
          Role.SiteManager,
          Role.UserManager,
        ],
      },
    },
    {
      path: "/users",
      name: "UsersManager",
      component: () => import("../components/Users/UsersManagement.vue"),
      meta: { authorize: [Role.UserManager, Role.SiteManager] },
    },
    {
      path: "/flightplans",
      name: "FlightplansForm",
      component: () => import("../components/Flightplans/FlightplansForm.vue"),
      meta: { authorize: [Role.FPManager, Role.FPViewer] },
    },
    {
      path: "/points",
      name: "PointsEdit",
      component: () =>
        import("../components/Flightplans/Points/PointsEdit.vue"),
      meta: { authorize: [Role.FPManager, Role.FPViewer] },
    },
    {
      path: "/segments",
      name: "SegmentsEdit",
      component: () =>
        import("../components/Flightplans/Segments/SegmentsEdit.vue"),
      meta: { authorize: [Role.FPManager, Role.FPViewer] },
    },
    {
      path: "/mission-maps",
      name: "MissionMaps",
      component: () =>
        import("../components/Flightplans/MissionMaps/MissionMapsEdit.vue"),
      meta: { authorize: [Role.FPManager, Role.FPViewer] },
    },
    {
      path: "/missions",
      name: "MissionsEdit",
      component: () =>
        import("../components/Flightplans/Missions/MissionsEdit.vue"),
      meta: { authorize: [Role.FPManager, Role.FPViewer] },
    },
    {
      path: "/drones",
      name: "DroneManagement",
      component: () => import("../components/Sites/Drones/DronesEdit.vue"),
      meta: { authorize: [Role.FPManager, Role.FPViewer] },
    },
    {
      path: "/sites",
      name: "SiteManagement",
      component: () => import("../components/Sites/SitesManagement.vue"),
      meta: { authorize: [Role.FPManager, Role.FPViewer, Role.SiteManager] },
    },
    {
      path: "/bases",
      name: "BaseManagement",
      component: () => import("../components/Sites/Bases/BasesEdit.vue"),
      meta: { authorize: [Role.FPManager, Role.FPViewer, Role.SiteManager] },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const currentUser = store.getters.getCurrentUserRoles();
  const loggedIn = store.getters.getLoginStatus();

  if (authorize) {
    if (!loggedIn && !currentUser) {
      return next({ path: "/login" });
    } else if (loggedIn && intersection(authorize, currentUser.roles).length === 0) {
      return next({ path: "/flightplans" });
    }
  }

  next();
});