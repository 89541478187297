<template>
<div>
  <a-row
    id="http-error-container"
    type="flex"
    justify="center"
  >
    <a-col :span="8" >
      <alert-box
        :status="status"
        :message="message"
      />
    </a-col>
  </a-row>
   <a-row
   style="marginTop: 1.6%"
    type="flex"
    justify="center"
    v-if="!message"
  >
  </a-row>
</div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { Row, Col } from 'ant-design-vue'
import AlertBox from './AlertBox'

Vue.use(Row)
Vue.use(Col)

export default {
  name: 'http-error',
  components: { AlertBox },
  computed: mapState({
    status: state => state.app.lastHTTPError.status,
    message: state => state.app.lastHTTPError.message,
  })
}
</script>

<style>
  #http-error-container {
    padding: 5px;
  }
</style>