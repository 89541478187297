import axios from "../plugin/axios";
import jwt_decode from "jwt-decode";
import { addApiPrefix, getErrorMsg } from "../helpers";
import { userTypes } from "../mutation-types";
import { router } from "../../router";

const accessToken = localStorage.getItem("accessToken");
const refreshToken = localStorage.getItem("refreshToken");

const state = {
  status: {
    loggedIn: accessToken ? true : false
  },
  accessToken: accessToken ? accessToken : null,
  refreshToken: refreshToken ? refreshToken : null
};

const getters = {
  getCurrentUserRoles: (state) => () => {
    if (state.accessToken) {
      let decodedToken = jwt_decode(state.accessToken);
      let roles = decodedToken.user_claims.roles.map((r) => r.name);
      const currentUserRoles = {
        email: decodedToken.identity,
        roles: roles,
      };
      return currentUserRoles;
    } else {
      return null;
    }
  },
  getLoginStatus: (state) => () => {
    return state.status.loggedIn;
  },
  isTokenExpired: (state) => () => {
    if (state.accessToken) {
      let decodedToken = jwt_decode(state.accessToken);
      let isExpired = decodedToken.exp < (Date.now() / 1000)
      if (isExpired) {
        return true
      }
    }
    return false;
  },
  getAccessToken: (state) => () => {
    return state.accessToken;
  },
  getRefreshToken: (state) => () => {
    return state.refreshToken;
  }
};

const mutations = {
  [userTypes.LOGIN_SUCCESS](state) {
    state.status = {...state.status, loggedIn: true};
  },
  [userTypes.LOGIN_FAILURE](state) {
    state.status = {...state.status, loggedIn: false};
  },
  [userTypes.LOGOUT](state) {
    state.status = {...state.status, loggedIn: false};
  },
  [userTypes.SET_ACCESS_TOKEN](state, accessToken) {
    state.accessToken = accessToken
  },
  [userTypes.SET_REFRESH_TOKEN](state, refreshToken) {
    state.refreshToken = refreshToken
  }
};

const actions = {
  login({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      axios
        .post(addApiPrefix("/login"), user)
        .then((resp) => {
          if (resp.data.payload.accessToken && resp.data.payload.refreshToken) {
            let accessToken = resp.data.payload.accessToken;
            let refreshToken = resp.data.payload.refreshToken;

            dispatch("setAccessToken", accessToken)
            .then(() => dispatch("setRefreshToken", refreshToken))
            .then(() => {
              commit(userTypes.LOGIN_SUCCESS)
              resolve();
            })
          }
        })
        .catch((error) => {
          commit(userTypes.LOGIN_FAILURE);
          reject(Error(getErrorMsg(error)));
        });
    });
  },
  logout({ commit, dispatch }) {
      return dispatch("setAccessToken", null)
      .then(() => dispatch("setRefreshToken", null))
      .then(() => commit(userTypes.LOGOUT))
  },
  setAccessToken({ commit }, accessToken) {
    if (accessToken === null) {
      localStorage.removeItem("accessToken");
    } else {
      localStorage.setItem("accessToken", accessToken)
    }
    commit(userTypes.SET_ACCESS_TOKEN, accessToken)
  },
  setRefreshToken({ commit }, refreshToken) {
    if (refreshToken === null) {
      localStorage.removeItem("refreshToken");
    } else {
      localStorage.setItem("refreshToken", refreshToken)
    }
    commit(userTypes.SET_REFRESH_TOKEN, refreshToken)
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
