<template>
  <div class="menu-container">
    <a-row style="width: 120px;">
      <div class="btn-collapsed">
        <a-button class="button-collapse" @click="$emit('collapse-btn-clicked')">
          <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
        </a-button>
      </div>
    </a-row>
    <a-menu
      :selectedKeys="currentlySelected"
      mode="inline"
      :inlineCollapsed="collapsed"
    >
      <a-menu-item-group v-if="isFPManager || isFPViewer" key="g1">
        <template slot="title">
          <a-icon type="build" /><span v-if="!collapsed">
            {{ $t("menu.missionBuilder") }}
          </span>
        </template>
        <a-menu-item v-if="isFPManager || isFPViewer" key="1">
          <router-link :to="{ name: 'FlightplansForm' }">
            <flightplan-icon />
            <span>{{ $t("menu.flightplans") }}</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="2">
          <router-link
            v-if="isFPManager || isFPViewer"
            :to="{ name: 'PointsEdit' }"
          >
            <point-icon />
            <span>{{ $t("menu.points") }}</span>
          </router-link>
        </a-menu-item>
        <a-menu-item v-if="isFPManager || isFPViewer" key="3">
          <router-link :to="{ name: 'SegmentsEdit' }">
            <segment-icon/>
            <span>{{ $t("menu.segments") }}</span>
          </router-link>
        </a-menu-item>
        <a-menu-item v-if="isFPManager || isFPViewer" key="4">
          <router-link :to="{ name: 'MissionMaps' }">
            <map-icon />
            <span>{{ $t("menu.missionMaps") }}</span>
          </router-link>
        </a-menu-item>
        <a-menu-item v-if="isFPManager || isFPViewer" key="5">
          <router-link :to="{ name: 'MissionsEdit' }">
            <mission-icon />
            <span>{{ $t("menu.missions") }}</span>
          </router-link>
        </a-menu-item>
      </a-menu-item-group>
      <a-menu-item-group key="g2" v-if="isSiteManager">
        <template slot="title">
          <management-icon /><span v-if="!collapsed">
            {{ $t("menu.management") }}
          </span>
        </template>
        <a-menu-item v-if="isFPManager || isFPViewer || isSiteManager" key="6">
          <router-link :to="{ name: 'SiteManagement' }">
            <a-icon type="apartment" />
            <span>{{ $t("menu.sites") }}</span>
          </router-link>
        </a-menu-item>
        <a-menu-item v-if="isFPManager || isFPViewer || isSiteManager" key="7">
          <router-link :to="{ name: 'BaseManagement' }">
            <a-icon type="container" />
            <span>{{ $t("menu.bases") }}</span>
          </router-link>
        </a-menu-item>
         <a-menu-item v-if="isFPManager || isFPViewer" key="8">
        <router-link :to="{ name: 'DroneManagement' }">
          <drone-icon />
          <span>{{ $t("menu.drones") }}</span>
        </router-link>
      </a-menu-item>
        <a-menu-item v-if="isUserManager || isSiteManager" key="9">
          <router-link :to="{ name: 'UsersManager' }">
            <usersManagement-icon />
            <span>{{ $t("menu.users") }}</span>
          </router-link>
        </a-menu-item>
      </a-menu-item-group>
    </a-menu>
  </div>
</template>

<script>
import Vue from "vue";
import { Menu, Button, Icon } from "ant-design-vue";
import {
  isFPManager,
  isFPViewer,
  isUserManager,
  isSiteManager,
} from "../utils/userAuth";
import icons from "../utils/icons"

Vue.use(Menu);
Vue.use(Button);
Vue.use(Icon);

const keys = {
  FlightplansForm: 1,
  PointsEdit: 2,
  SegmentsEdit: 3,
  MissionMaps: 4,
  MissionsEdit: 5,
  SiteManagement: 6,
  BaseManagement: 7,
  DroneManagement: 8,
  UsersManager: 9,
};

export default {
  name: "left-menu",
  props: {
    collapsed: Boolean,
  },
  components: icons,
  data() {
    return {
      currentlySelected: [],
    };
  },
  mounted() {
    if (matchMedia) {
      const mq = window.matchMedia("(min-width: 1200px)");
      mq.addListener(this.WidthChange);
      this.WidthChange(mq);
    }
  },
  computed: {
    isFPManager() {
      return isFPManager();
    },
    isFPViewer() {
      return isFPViewer();
    },
    isUserManager() {
      return isUserManager();
    },
    isSiteManager() {
      return isSiteManager();
    },
  },
  methods: {
    updateMenuCollapsed(mq) {
      if (!mq.matches) {

      var updatedPreferences = {...this.$store.getters.getUserPreferences()}
        updatedPreferences.menuCollapsed = true;
        this.$store.dispatch("setUserPreferences", {
          preferences: updatedPreferences,
          user: {...this.$store.getters.getCurrentUser()}
        });
      }
    },
    WidthChange(mq) {
      this.$store.dispatch("setCurrentUser")
      .then(res => this.updateMenuCollapsed(mq))
    }
  },
  watch: {
    $route() {
      if (this.$route.name !== null) {
        if (Object.hasOwnProperty.call(keys, this.$route.name.toString())) {
          this.currentlySelected = [keys[this.$route.name].toString()];
        } else {
          this.currentlySelected = [];
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.menu-container {
  width: 250px;
}

.btn-collapsed {
  float: left;
  padding: 10px;
}

.ant-menu-item {
  > a {
    float: left;
  }
}

.ant-menu-submenu {
  float: left;
}

.button-collapse {
  color: #fff;
  background-color: #38bbd4;
  border-color: #38bbd4;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
</style>
