export const flightplanTypes = {
  FETCH_FLIGHTPLANS: "FETCH_FLIGHTPLANS",
  UPDATE_FLIGHTPLAN: "UPDATE_FLIGHTPLAN",
  ADD_FLIGHTPLAN: "ADD_FLIGHTPLAN",
  DELETE_FLIGHTPLAN: "DELETE_FLIGHTPLAN",
  SET_CURRENT_FLIGHTPLAN_ID: "SET_CURRENT_FLIGHTPLAN_ID",
};

export const pointTypes = {
  UPDATE_POINT: "UPDATE_POINT",
  ADD_POINT: "ADD_POINT",
  ADD_POINTS: "ADD_POINTS",
  DELETE_POINT: "DELETE_POINT",
  DELETE_MULTIPLE_POINTS: "DELETE_MULTIPLE_POINTS",
};

export const missionMapTypes = {
  UPDATE_MISSION_MAP: "UPDATE_MISSION_MAP",
  ADD_MISSION_MAP: "ADD_MISSION_MAP",
  DELETE_MISSION_MAP: "DELETE_MISSION_MAP",
};

export const missionTypes = {
  UPDATE_MISSION: "UPDATE_MISSION",
  ADD_MISSION: "ADD_MISSION",
  DELETE_MISSION: "DELETE_MISSION",
};

export const segmentTypes = {
  UPDATE_SEGMENT: "UPDATE_SEGMENT",
  ADD_SEGMENT: "ADD_SEGMENT",
  DELETE_SEGMENT: "DELETE_SEGMENT",
};

export const geofencePointsTypes = {
  UPDATE_GEOFENCE_POINTS: "UPDATE_GEOFENCE_POINTS",
  DELETE_GEOFENCE_POINTS: "DELETE_GEOFENCE_POINTS",
};

export const siteTypes = {
  FETCH_SITES: "FETCH_SITES",
  UPDATE_SITE: "UPDATE_SITE",
  ADD_SITE: "ADD_SITE",
  DELETE_SITE: "DELETE_SITE",
  SET_CURRENT_SITE_ID: "SET_CURRENT_SITE_ID",
};

export const baseTypes = {
  SET_CURRENT_BASE_ID: "SET_CURRENT_BASE_ID",
  FETCH_BASES: "FETCH_BASES",
  UPDATE_BASE: "UPDATE_BASE",
  ADD_BASE: "ADD_BASE",
  DELETE_BASE: "DELETE_BASE",
};

export const droneTypes = {
  UPDATE_DRONE: "UPDATE_DRONE",
  ADD_DRONE: "ADD_DRONE",
  DELETE_DRONE: "DELETE_DRONE",
};

export const userTypes = {
  FETCH_USERS: "FETCH_USERS",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  DELETE_USER: "DELETE_USER",
  ADD_USER: "ADD_USER",
  UPDATE_PREFERENCES: "UPDATE_PREFERENCES",
  UPDATE_PREFERENCE_USER: "UPDATE_PREFERENCE_USER",
  DELETE_USER_PREFERENCES: "DELETE_USER_PREFERENCES",
  UPDATE_USER: "UPDATE_USER",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT: "LOGOUT",
  SET_USER_PREFERENCES: "SET_USER_PREFERENCES",
  SET_USER_LAYOUT: "SET_USER_LAYOUT",
  SET_USER_MENUCOLLAPSED: "SET_USER_MENUCOLLAPSED",
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
  SET_ZOOM: "SET_ZOOM",
  SET_CENTER: "SET_CENTER",
};

export const SET_LAST_HTTP_ERROR = "SET_LAST_HTTP_ERROR";
