import store from "../../store";
import { addApiPrefix, getErrorMsg, header } from "../helpers";
import axios from "../plugin/axios";
import { userRoleGroup } from "../../utils/userAuth";
import i18n from '../../i18n';

const { userTypes } = require("../mutation-types");

const state = {
  lastFetch: null,
  menuCollapsed: false,
  mapMode: "SATELLITE",
  showMap: true,
  langage: "en",
  layout: "vertical",
  zoom: 20,
  lat: null,
  lng: null,
};

function update_layout(layout) {
  state.layout = layout
}

function update_zoom(zoom) {
  state.zoom = zoom
}

function update_center(lat, lng) {
  state.lat = lat
  state.lng = lng
}

function fetch_user_empty() {
  store.dispatch("setUserPreferences", {
    preferences: {...store.getters.getUserPreferences()},
    user: {...store.getters.getCurrentUser()}
  });
}

const getters = {
  getZoom: (state) => () => {
    return state.zoom
  },
  getCenter: (state) => () => {
    return {
      lat: state.lat,
      lng: state.lng,
    };
  },
  getUserPreferences: (state) => () => {
    return {
      menuCollapsed: state.menuCollapsed,
      mapMode: state.mapMode,
      showMap: state.showMap,
      langage: state.langage,
      layout: state.layout,
    };
  },
};

const mutations = {
  [userTypes.SET_USER_PREFERENCES](state, preferences) {
    state.menuCollapsed = preferences.menuCollapsed;
    state.mapMode = preferences.mapMode;
    state.showMap = preferences.showMap;
    state.langage = preferences.langage;
    state.layout = preferences.layout;
    state.lastFetch = new Date();
  },
  [userTypes.DELETE_USER_PREFERENCES](state) {
    state.menuCollapsed = false;
    state.mapMode = "SATELLITE";
    state.showMap = true;
    state.langage = "en";
    state.layout = "vertical";
    state.lastFetch = null;
  },
  [userTypes.SET_USER_LAYOUT](state, preferences) {
    state.layout = preferences.layout;
  },
  [userTypes.SET_USER_MENUCOLLAPSED](state, preferences) {
    state.menuCollapsed = preferences.menuCollapsed;
  },
  [userTypes.UPDATE_PREFERENCE_USER](state,  layout) {
    update_layout(layout)
  },
  [userTypes.SET_ZOOM](state,  zoom) {
    update_zoom(zoom)
  },
  [userTypes.SET_CENTER](state,  position) {
    update_center(position.lat, position.lng)
  },
  [userTypes.UPDATE_PREFERENCES] (state, preferences) {
    const currentLang = state.langage

    if (currentLang != preferences.langage) {
      i18n.locale = preferences.langage
    }
  }
};

const actions = {
  fetchUserPreferences({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(addApiPrefix('/users/current'), header())
        .then((preferences) => {
          if (Object.keys(preferences.data.payload.user.preferences).length !== 0) {
            commit(userTypes.SET_USER_PREFERENCES, preferences.data.payload.user.preferences);
            resolve();
          }
          else
            fetch_user_empty()
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
    });
  },

  setUserPreferences({ commit }, {preferences, user}) {
    user.preferences = preferences
    user.role = userRoleGroup(user.roles)

    return new Promise((resolve, reject) => {
      axios
        .put(addApiPrefix(`/users/${user.id}`), user, header())
        .then((preferences) => {
          commit(userTypes.UPDATE_PREFERENCES, preferences.data.payload.user.preferences)
          commit(userTypes.SET_USER_PREFERENCES, preferences.data.payload.user.preferences);
          resolve();
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
      });
  },
  setLayoutPreference({ commit }, {preferences, user, layout}) {
   preferences.layout = layout
   user.preferences = preferences
   user.role = userRoleGroup(user.roles)

   return new Promise((resolve, reject) => {
     axios
       .put(addApiPrefix(`/users/${user.id}`), user, header())
       .then((preferences) => {
         commit(userTypes.SET_USER_LAYOUT, preferences.data.payload.user.preferences);
         resolve();
       })
       .catch((error) => {
         reject(Error(getErrorMsg(error)));
       });
     });
  },
  setMenuCollapsedPreference({ commit }, {preferences, user, menuCollapsed}) {
    preferences.menuCollapsed = menuCollapsed
    user.preferences = preferences
    user.role = userRoleGroup(user.roles)
    return new Promise((resolve, reject) => {
      axios
        .put(addApiPrefix(`/users/${user.id}`), user, header())
        .then((preferences) => {
          commit(userTypes.SET_USER_MENUCOLLAPSED, preferences.data.payload.user.preferences);
          resolve();
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
      });
   },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
