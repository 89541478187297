import axios from "../plugin/axios";
import { addApiPrefix, getErrorMsg, header } from "../helpers";
import { siteTypes } from "../mutation-types";

function updateSiteObj(site, obj, objName) {
  let siteIndex = state.sites.findIndex((s) => s.id === site.id);
  if (siteIndex !== -1) {
    let objIndex = state.sites[siteIndex][objName].findIndex(
      (o) => o.id === obj.id
    );

    if (objIndex !== -1) {
      state.sites[siteIndex][objName][objIndex] = Object.assign({}, obj);
    }
  }
}

function addSiteObj(site, obj, objName) {
  let siteIndex = state.sites.findIndex((s) => s.id === site.id);

  if (siteIndex !== -1) {
    state.sites[siteIndex][objName].unshift(obj);
  }
}

function deleteSiteObj(site, obj, objName) {
  let siteIndex = state.sites.findIndex((s) => s.id === site.id);

  if (siteIndex !== -1) {
    let objIdx = state.sites[siteIndex][objName].findIndex(
      (o) => o.id === obj.id
    );

    if (objIdx !== -1) {
      state.sites[siteIndex][objName].splice(objIdx, 1);
    }
  }
}

const state = {
  sites: [],
  lastFetch: null,
  currentSiteId: null,
};

const mutations = {
  [siteTypes.FETCH_SITES](state, sites) {
    state.sites = [...sites];
    state.lastFetch = new Date();
  },
  [siteTypes.UPDATE_SITE](state, site) {
    let siteIndex = state.sites.findIndex((s) => s.id === site.id);

    if (siteIndex !== -1) {
      state.sites[siteIndex] = site;
    }
  },
  [siteTypes.ADD_SITE](state, site) {
    state.sites.push(site);
  },
  [siteTypes.DELETE_SITE](state, site) {
    let siteIndex = state.sites.findIndex((s) => s.id === site.id);

    if (siteIndex !== -1) {
      state.sites.splice(siteIndex, 1);
    }
  },
  [siteTypes.SET_CURRENT_SITE_ID](state, siteId) {
    state.currentSiteId = siteId;
  },
};

const actions = {
  fetchSites({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(addApiPrefix("/sites"), header())
        .then((resp) => {
          commit(siteTypes.FETCH_SITES, resp.data.payload.sites);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateSite({ commit }, site) {
    return new Promise((resolve, reject) => {
      axios
        .put(addApiPrefix(`/sites/${site.id}`), site, header())
        .then((resp) => {
          site = resp.data.payload.site;
          commit(siteTypes.UPDATE_SITE, site);
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  addSite({ commit }, site) {
    return new Promise((resolve, reject) => {
      site = Object.assign({}, site);
      delete site.id;
      axios
        .post(addApiPrefix(`/sites`), site, header())
        .then((resp) => {
          site = resp.data.payload.site;
          commit(siteTypes.ADD_SITE, site);
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  deleteSite({ commit }, site) {
    return new Promise((resolve, reject) => {
      axios
        .delete(addApiPrefix(`/sites/${site.id}`), header())
        .then(() => {
          commit(siteTypes.DELETE_SITE, site);
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  setCurrentSiteId({ commit }, currentSiteId) {
    commit(siteTypes.SET_CURRENT_SITE_ID, currentSiteId);
  },
};

const getters = {
  getSiteById: (state) => (id) => {
    return state.sites.find((s) => s.id === id);
  },
  getCurrentSite: (state) => () => {
    const currentId = state.currentSiteId;
    return state.sites.find((s) => s.id === currentId);
  },
};

export { addSiteObj, updateSiteObj, deleteSiteObj };

export default {
  state,
  mutations,
  actions,
  getters,
};
