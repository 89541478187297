<template>
  <div v-show="status">
    <a-alert
      v-if="showErrorAlert"
      :message="description"
      type="error"
      closable
      banner
      @close="resetLastHttpError"
    >
    </a-alert>
    <a-alert
      v-else-if="showSuccessAlert"
      :message="messageSuccess"
      type="success"
      closable
      banner
      @close="resetLastHttpError"
    >
    </a-alert>
  </div>
</template>

<script>
import Vue from 'vue'
import { Alert } from 'ant-design-vue'

Vue.use(Alert)

export default {
  name: 'alert-box',
  props: {
    status: Number,
    message: String
  },
  data() {
    return {
      showErrorAlert: false,
      showSuccessAlert: false
    }
  },
  computed: {
    description() {
      return `${this.$t('error.' + this.message)}`
    },
    messageSuccess() {
      return `${this.$t('error.' + this.message)}`;
    }
  },
  methods: {
    resetLastHttpError () {
      this.$store.dispatch('setLastHTTPError', {status: null, message: null})
    },
  },
  watch: {
    status: function(newValue) {
      if (newValue) {
        this.showErrorAlert = newValue !== 201 && newValue !== 200 ? true : false;
        this.showSuccessAlert = newValue === 201 ? true : false;
      } else {
        this.showErrorAlert = false;
        this.showSuccessAlert = false;
      }
    },
    message: function(newValue) {
      if (newValue) {
        setTimeout(this.resetLastHttpError, 7500);
      }
    }
  }
}
</script>