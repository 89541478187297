import axios from "../plugin/axios";
import { addApiPrefix, getErrorMsg, header } from "../helpers";
import {
  updateFlightplanObj,
  addFlightplanObj,
  deleteFlightplanObj,
  deleteFlightMultipleObj,
} from "./flightPlans";

import { pointTypes } from "../mutation-types";

const state = {
  altitude: 50,
  pointsUsed: [],
};

function addPointsUsed(pointsUsed) {
  state.pointsUsed = pointsUsed
}

const getters = {
  getAltitude () {
    return state.altitude
  },
  getErrorPoints () {
    return state.pointsUsed
  }
};

const mutations = {
  [pointTypes.UPDATE_POINT](state, { flightPlan, point }) {
    updateFlightplanObj(flightPlan, point, "points");
  },
  [pointTypes.ADD_POINT](state, { flightPlan, point }) {
    addFlightplanObj(flightPlan, point, "points");
  },
  [pointTypes.DELETE_POINT](state, { flightPlan, point }) {
    deleteFlightplanObj(flightPlan, point, "points");
  },
  [pointTypes.DELETE_MULTIPLE_POINTS](state, { flightPlan, points }) {
    deleteFlightMultipleObj(flightPlan, points, "points");
  },
  updateAltitude (state, n) {
    state.altitude = n
  },
  [pointTypes.DELETE_MULTIPLE_POINTS](state, { flightPlan, points }) {
    deleteFlightMultipleObj(flightPlan, points, "points");
  },
  [pointTypes.ADD_POINTS](state,  points) {
    addPointsUsed(points)
  },
};

const actions = {
  updatePoint({ commit }, { flightPlan, point }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          addApiPrefix(`/flightplans/${flightPlan.id}/points/${point.id}`),
          point,
          header()
        )
        .then((resp) => {
          point = resp.data.payload.point;
          commit(pointTypes.UPDATE_POINT, { flightPlan, point });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  addPoint({ commit }, { flightPlan, point }) {
    return new Promise((resolve, reject) => {
      point = Object.assign({}, point);
      delete point.id;
      axios
        .post(
          addApiPrefix(`/flightplans/${flightPlan.id}/points`),
          point,
          header()
        )
        .then((resp) => {
          point = resp.data.payload.point;
          commit(pointTypes.ADD_POINT, { flightPlan, point });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  deletePoint({ commit }, { flightPlan, point }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          addApiPrefix(`/flightplans/${flightPlan.id}/points/${point.id}`),
          header()
        )
        .then(() => {
          commit(pointTypes.DELETE_POINT, { flightPlan, point });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  deleteMultiplePoints({ commit }, { flightPlan, points }) {
    return new Promise((resolve, reject) => {
      axios
      .post(addApiPrefix(`/flightplans/${flightPlan.id}/points?action=delete`), {"pointsIds": points}, header())
      .then(() => {
        commit(pointTypes.DELETE_MULTIPLE_POINTS, { flightPlan, points });
        resolve()
      })
      .catch((err) => {
        reject(Error(getErrorMsg(err)));
      });

    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
