import axios from "../plugin/axios";
import { addApiPrefix, getErrorMsg, header } from "../helpers";
import {
  updateFlightplanObj,
  addFlightplanObj,
  deleteFlightplanObj,
} from "./flightPlans";

import { missionTypes } from "../mutation-types";

const state = {};
const getters = {};

const actions = {
  updateMission({ commit }, { flightPlan, mission }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          addApiPrefix(`/flightplans/${flightPlan.id}/missions/${mission.id}`),
          mission,
          header()
        )
        .then((resp) => {
          mission = resp.data.payload.mission;
          commit(missionTypes.UPDATE_MISSION, { flightPlan, mission });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  addMission({ commit }, { flightPlan, mission }) {
    return new Promise((resolve, reject) => {
      mission = Object.assign({}, mission);
      delete mission.id;

      axios
        .post(
          addApiPrefix(`/flightplans/${flightPlan.id}/missions`),
          mission,
          header()
        )
        .then((resp) => {
          mission = resp.data.payload.mission;
          commit(missionTypes.ADD_MISSION, { flightPlan, mission });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  deleteMission({ commit }, { flightPlan, mission }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          addApiPrefix(`/flightplans/${flightPlan.id}/missions/${mission.id}`),
          header()
        )
        .then(() => {
          commit(missionTypes.DELETE_MISSION, { flightPlan, mission });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
};

const mutations = {
  [missionTypes.UPDATE_MISSION](state, { flightPlan, mission }) {
    updateFlightplanObj(flightPlan, mission, "missions");
  },
  [missionTypes.ADD_MISSION](state, { flightPlan, mission }) {
    addFlightplanObj(flightPlan, mission, "missions");
  },
  [missionTypes.DELETE_MISSION](state, { flightPlan, mission }) {
    deleteFlightplanObj(flightPlan, mission, "missions");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
