import store from ".";

function addApiPrefix(url) {
  const apiPrefix = store.state.app.config.apiPrefix;
  return `${apiPrefix}${url}`;
}

function getErrorMsg(error) {
  let errorMsg = "Unknown error";

  if (Object.hasOwnProperty.call(error, "response")) {
    if (
      Object.hasOwnProperty.call(error.response, "data") &&
      error.response.data &&
      Object.hasOwnProperty.call(error.response.data, "errorCode")
    ) {
      errorMsg = error.response.data.errorCode;
    } else {
      errorMsg += ` (http code: ${error.response.code})`;
    }
  }

  return errorMsg;
}

function dispatchApiAction(action, params = {}) {
  return new Promise((resolve, reject) => {
    store
      .dispatch(action, params)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function header() {
  const accessToken = store.state.auth.accessToken || {};
  const authHeader = accessToken
    ? { 'Authorization': `Bearer ${accessToken}` }
    : {};
  return {
    headers: {
      ...authHeader,
      "Content-Type": "application/json",
    },
  };
}

export { addApiPrefix, getErrorMsg, dispatchApiAction, header };
