import axios from "../plugin/axios";
import { addApiPrefix, getErrorMsg, header } from "../helpers";
import {
  updateFlightplanObj,
  addFlightplanObj,
  deleteFlightplanObj,
} from "./flightPlans";

import { segmentTypes } from "../mutation-types";

const state = {};

const getters = {};

const mutations = {
  [segmentTypes.UPDATE_SEGMENT](state, { flightPlan, segment }) {
    updateFlightplanObj(flightPlan, segment, "segments");
  },
  [segmentTypes.ADD_SEGMENT](state, { flightPlan, segment }) {
    addFlightplanObj(flightPlan, segment, "segments");
  },
  [segmentTypes.DELETE_SEGMENT](state, { flightPlan, segment }) {
    deleteFlightplanObj(flightPlan, segment, "segments");
  },
};

const actions = {
  updateSegment({ commit }, { flightPlan, segment }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          addApiPrefix(`/flightplans/${flightPlan.id}/segments/${segment.id}`),
          segment,
          header()
        )
        .then((resp) => {
          segment = resp.data.payload.segment;
          commit(segmentTypes.UPDATE_SEGMENT, { flightPlan, segment });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  addSegment({ commit }, { flightPlan, segment }) {
    return new Promise((resolve, reject) => {
      segment = Object.assign({}, segment);
      delete segment.id;

      axios
        .post(
          addApiPrefix(`/flightplans/${flightPlan.id}/segments`),
          segment,
          header()
        )
        .then((resp) => {
          segment = resp.data.payload.segment;
          commit(segmentTypes.ADD_SEGMENT, { flightPlan, segment });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  deleteSegment({ commit }, { flightPlan, segment }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          addApiPrefix(`/flightplans/${flightPlan.id}/segments/${segment.id}`),
          header()
        )
        .then(() => {
          commit(segmentTypes.DELETE_SEGMENT, { flightPlan, segment });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
