<template>
  <div v-if="routing">
    <a-col style="marginTop: 17%" :span="3" :offset="12">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 40px" spin />
      </a-spin>
    </a-col>
  </div>
  <div v-else class="login-container">
    <a-row>
      <a-col :span="16" :offset="3">
        <http-error />
      </a-col>
    </a-row>
    <a-row style="marginTop: 20px;">
      <a-col :span="6" :offset="8">
        <a-card class="card-form">
          <a-row>
            <a-row>
              <a-col :span="12" :offset="5">
                <img :src="imagePath" class="logo" />
              </a-col>
            </a-row>
          </a-row>
          <a-row>
            <template>
              <a-form-model
                layout="horizontal"
                ref="loginForm"
                :model="loginForm"
                @submit="handleLogin"
                @submit.native.prevent
              >
                <a-form-model-item
                  hasFeedback
                  :validate-status="
                    invalidFields.indexOf('email') === -1 ? '' : 'error'
                  "
                >
                  <a-input
                    v-model="loginForm.email"
                    :placeholder="$t('users.email')"
                    @change="(e) => handleChange('email', e.target.value)"
                  >
                    <a-icon
                      slot="prefix"
                      type="user"
                      style="color:rgba(0,0,0,.25)"
                    />
                  </a-input>
                  <div
                    v-if="submitted && $v.loginForm.email.$error"
                    style="color: red; marginBottom: -1.5rem; marginTop: -0.5rem;"
                  >
                    <span v-if="!$v.loginForm.email.required">
                      {{ $t("authentication.emailRequired") }}
                    </span>
                    <span v-if="!$v.loginForm.email.email">
                      {{ $t("authentication.emailInvalid") }}
                    </span>
                  </div>
                </a-form-model-item>
                <a-form-model-item
                  hasFeedback
                  :validate-status="
                    invalidFields.indexOf('password') === -1 ? '' : 'error'
                  "
                >
                  <a-input
                    v-model="loginForm.password"
                    type="password"
                    :placeholder="$t('users.password')"
                    @change="(e) => handleChange('password', e.target.value)"
                  >
                    <a-icon
                      slot="prefix"
                      type="lock"
                      style="color:rgba(0,0,0,.25)"
                    />
                  </a-input>
                  <div
                    v-if="submitted && $v.loginForm.password.$error"
                    style="color: red; marginBottom: -1.5rem; marginTop: -0.5rem;"
                  >
                    <span v-if="!$v.loginForm.password.required">
                      {{ $t("authentication.passwordRequired") }}
                    </span>
                  </div>
                </a-form-model-item>
                <div style="marginBottom: 2%">
                <!-- <a href="#"><u> Mot de passe oublié ?</u> </a> -->
                </div>
                <a-form-model-item>
                  <a-button
                    class="btn btn-primary btn-login"
                    type="primary"
                    :disabled="
                      loading ||
                        loginForm.email === '' ||
                        loginForm.password === ''
                    "
                    html-type="submit"
                  >
                    <span v-show="loading">
                      <a-icon type="loading"></a-icon>
                    </span>
                    <strong> {{ $t("authentication.login") }} </strong>
                  </a-button>
                </a-form-model-item>
              </a-form-model>
            </template>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const image = require.context("../assets", true);
import { Role } from "../utils/userRoles";
import { required, email } from "vuelidate/lib/validators";
import { router } from "../router";
import { Row, Col, Input, FormModel, Card, Button, Spin } from "ant-design-vue";
import HttpError from "./ErrorManager/HttpError";
import Vue from "vue";

Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(FormModel);
Vue.use(Card);
Vue.use(Button);
Vue.use(Spin);

export default {
  name: "Login",
  components: {
    HttpError,
  },
  data() {
    return {
      imagePath: image("./logo_svg_dark.svg"),
      loading: false,
      submitted: false,
      message: "",
      loginForm: {
        email: "",
        password: "",
      },
      invalidFields: [],
      routing: false,
    };
  },
  validations: {
    loginForm: {
      email: { required, email },
      password: { required },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.getLoginStatus();
    },
  },
  created() {
    this.routing = false;
    if (this.loggedIn) {
      this.routing = true;
      router.push("/");
    }
  },
  methods: {
    handleChange(field, value) {
      this.invalidFields[field] = "";
      this.loginForm[field] = value;
    },
    handleLogin() {
      this.submitted = true;
      this.invalidFields = [];
      this.$v.$touch();
      if (this.$v.$invalid) {
        if (!this.$v.loginForm.password.required) {
          this.invalidFields.push("password");
        }
        if (
          !this.$v.loginForm.email.email ||
          !this.$v.loginForm.email.required
        ) {
          this.invalidFields.push("email");
        }
        return;
      }
      this.loading = true;
      if (this.loginForm.email && this.loginForm.password) {
        this.$store
          .dispatch("login", this.loginForm)
          .then(() => {
            this.routing = true;
            this.$store.dispatch("setCurrentUser")
            let current = this.$store.getters.getCurrentUserRoles();
            if (current) {
              let roles = current.roles;
              if (roles.includes(Role.UserManager)) {
                router.push("/users");
              } else if (roles.includes(Role.SiteManager)) {
                router.push("/sites");
              } else {
                router.push("/flightplans");
              }
            }
          })
          .catch(() => {
            this.submitted = false;
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  margin-top: 11%;
  justify-content: center;
}
.logo {
  width: 225px;
}

.card-form {
  background-color: #ffffff;
  border: solid #124c5a 3px;
  border-radius: 20px;
}

.ant-input {
  height: 50px;
}

label {
  display: block;
  margin-top: 5%;
}

.btn-login {
  width: 100%;
  height: 35px;
  font-size: 1.2rem;
}
</style>
