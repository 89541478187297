const MapSvg = {
  template: `
  <svg 
    id="Capa_1" 
    enable-background="new 0 0 512 512" 
    height="512" 
    viewBox="0 0 512 512" 
    width="512"
  >
    <g>
      <path d="m499.924 113.264c-173.716-34.527-162.471-32.724-166.405-32.062-1.258.21-16.676 3.309-52.613 10.496-15.066-43.444-56.399-74.721-104.906-74.721-48.552 0-89.918 31.336-104.949 74.844l-53.127-10.557c-9.26-1.836-17.924 5.243-17.924 14.713v352c0 7.157 5.057 13.317 12.076 14.712 172.248 34.236 160.971 32.026 162.113 32.165 3.622.445-7.572 2.297 161.82-31.582l158.067 31.417c9.265 1.839 17.924-5.252 17.924-14.712v-352c0-7.157-5.057-13.317-12.076-14.713zm-323.924-66.287c44.663 0 81 36.336 81 81 0 37.683-60.298 133.338-81 164.989-20.679-31.616-81-127.298-81-164.989 0-44.664 36.337-81 81-81zm-146 67.273 35.21 6.997c-.134 2.227-.21 4.469-.21 6.729 0 25.014 16.363 65.381 50.025 123.409 19.656 33.883 39.033 62.974 45.975 73.221v137.095l-131-26.037zm161 210.356c6.941-10.247 26.319-39.337 45.975-73.221 33.662-58.028 50.025-98.395 50.025-123.408 0-2.304-.079-4.59-.218-6.859l34.218-6.845v321.407l-130 26zm291 137.096-131-26.037v-321.414l131 26.037z"/>
      <path d="m176 174.977c25.916 0 47-21.084 47-47s-21.084-47-47-47-47 21.084-47 47 21.084 47 47 47zm0-64c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z"/>
    </g>
  </svg>
  `,
};

const MapIcon = {
  template: `
    <a-icon :component="MapSvg" />
  `,
  data() {
    return {
      MapSvg,
    };
  },
};

export default MapIcon;
