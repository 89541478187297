export const Role = {
  FPManager: "FPManager",
  FPViewer: "FPViewer",
  UserManager: "UserManager",
  SiteManager: "SiteManager",
};
  
export const RoleGroup = {
  Telepilot: "Telepilot",
  Integrator: "Integrator",
  Admin: "Admin",
};