import axios from "axios";
import store from "..";
import { router } from '../../router'
import { addApiPrefix } from "../helpers";
import { pointTypes } from "../mutation-types";

axios.interceptors.request.use(
  (config) => {
    if (
      store.state.app.lastHTTPError.status !== null &&
      store.state.app.lastHTTPError.message !== null
    ) {
      store.dispatch("setLastHTTPError", {
        status: null,
        message: null,
      });
    }
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("setLastHTTPError", {
        status: error.response.status,
        message: "unauthorized",
      });
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (resp) => {
    store.dispatch("setLastHTTPError", {
      status: resp.status,
      message: resp.data.errorCode,
    });
    return Promise.resolve(resp);
  },
  (error) => {
    if (!error.status) {
      store.dispatch("setLastHTTPError", {
        status: 500,
        message: "networkError",
      });
    }
    if (error.response.status === 401) {
      const errorCode = error.response?.data?.errorCode

      if (errorCode === "accessTokenExpired") {
        const originalRequest = error.config;

        if (!originalRequest._retry) {
          const refreshToken = store.getters.getRefreshToken();
          originalRequest._retry = true;

          return axios({
            method: 'post',
            url: addApiPrefix("/login/refresh"),
            headers: { 'Authorization': `Bearer ${refreshToken}` }
          })
          .then((resp) => {
            if (resp.status === 200) {
              const accessToken = resp.data.payload?.accessToken;

              if (accessToken) {
                store.dispatch("setAccessToken", accessToken);
                originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
                return axios(originalRequest);
              }
            }
          })
        }
      }

      store.dispatch("logout")
      .then(() => router.push({ path:'/login' }))
    } else {
      const errorCode = error.response?.data?.errorCode
      if (errorCode === "pointMultipleInUse") {
        var data = error.response?.data?.payload?.pointsInUse
        store.commit(pointTypes.ADD_POINTS,  data );
      }
      store.dispatch("setLastHTTPError", {
        status: error.response.status,
        message: error.response.data.errorCode,
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
