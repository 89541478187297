import { baseTypes } from "../mutation-types";
import axios from "../plugin/axios";
import { addApiPrefix, getErrorMsg, header } from "../helpers";
import { updateSiteObj, addSiteObj, deleteSiteObj } from "./sites";

function updateBaseObj(base, obj, objName) {
  let baseIndex = state.bases.findIndex((s) => s.id === base.id);
  if (baseIndex !== -1) {
    let objIndex = state.bases[baseIndex][objName].findIndex(
      (o) => o.id === obj.id
    );

    if (objIndex !== -1) {
      state.bases[baseIndex][objName][objIndex] = Object.assign({}, obj);
    }
  }
}

function addBaseObj(base, obj, objName) {
  let baseIndex = state.bases.findIndex((s) => s.id === base.id);

  if (baseIndex !== -1) {
    state.bases[baseIndex][objName].unshift(obj);
  }
}

function deleteBaseObj(base, obj, objName) {
  let baseIndex = state.bases.findIndex((s) => s.id === base.id);

  if (baseIndex !== -1) {
    let objIdx = state.bases[baseIndex][objName].findIndex(
      (o) => o.id === obj.id
    );

    if (objIdx !== -1) {
      state.bases[baseIndex][objName].splice(objIdx, 1);
    }
  }
}

const state = {
  bases: [],
  lastFetch: null,
  currentBaseId: null,
};

const getters = {
  getBaseById: (state) => (id) => {
    return state.bases.find((b) => b.id === id);
  },
  getCurrentBase: (state) => () => {
    const currentId = state.currentBaseId;
    return state.bases.find((b) => b.id === currentId);
  },
};

const mutations = {
  [baseTypes.ADD_BASE](state, { site, base }) {
    addSiteObj(site, base, "bases");
  },
  [baseTypes.UPDATE_BASE](state, { site, base }) {
    updateSiteObj(site, base, "bases");
  },
  [baseTypes.DELETE_BASE](state, { site, base }) {
    deleteSiteObj(site, base, "bases");
  },
  [baseTypes.SET_CURRENT_BASE_ID](state, baseId) {
    state.currentBaseId = baseId;
  },
  [baseTypes.FETCH_BASES](state, bases) {
    state.bases = [...bases];
    state.lastFetch = new Date();
  },
};

const actions = {
  fetchBases({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(addApiPrefix("/bases"), header())
        .then((resp) => {
          commit(baseTypes.FETCH_BASES, resp.data.payload.bases);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateBase({ commit }, { site, base }) {
    return new Promise((resolve, reject) => {
      axios
        .put(addApiPrefix(`/sites/${site.id}/bases/${base.id}`), base, header())
        .then((resp) => {
          base = resp.data.payload.base;
          commit(baseTypes.UPDATE_BASE, { site, base });
          resolve();
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
    });
  },
  addBase({ commit }, { site, base }) {
    return new Promise((resolve, reject) => {
      base = Object.assign({}, base);
      delete base.id;
      axios
        .post(addApiPrefix(`/sites/${site.id}/bases`), base, header())
        .then((resp) => {
          base = resp.data.payload.base;
          commit(baseTypes.ADD_BASE, { site, base });
          resolve();
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
    });
  },
  deleteBase({ commit }, { site, base }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          addApiPrefix(`/sites/${site.id}/bases/${base.id}`),
          header()
        )
        .then(() => {
          commit(baseTypes.DELETE_BASE, { site, base });
          resolve();
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
    });
  },
  setCurrentBaseId({ commit }, currentBaseId) {
    commit(baseTypes.SET_CURRENT_BASE_ID, currentBaseId);
  },
};

export { updateBaseObj, addBaseObj, deleteBaseObj };

export default {
  state,
  getters,
  mutations,
  actions,
};
