const SegmentSvg = {
  template: `
  <svg 
    x="0px" 
    y="0px"
    viewBox="0 0 492.308 492.308" 
    style="enable-background:new 0 0 492.308 492.308;" 
    xml:space="preserve"
  >
    <g>
      <g>
        <path d="M346.587,0v131.798L131.803,346.582H0v145.726h145.726V360.505L360.51,145.721h131.798V0H346.587z M126.034,472.615
          H19.692V366.274h106.341V472.615z M472.615,126.029H366.279V19.692h106.337V126.029z"/>
      </g>
    </g>
    <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
  </svg>
  `,
};

const SegmentIcon = {
  template: `
    <a-icon :component="SegmentSvg" />
  `,
  data() {
    return {
      SegmentSvg,
    };
  },
};

export default SegmentIcon;
