<template>
  <div class="header-menu">
    <div class="admin-header-right">
      <a-dropdown>
        <div class="header-avatar" style="cursor: pointer">
          <a-avatar
            class="avatar"
            size="large"
            shape="square"
            icon="user"
            :style="{ backgroundColor: '#38BBD4', verticalAlign: 'middle' }"
          />
        </div>
        <a-menu :class="['avatar-menu']" slot="overlay">
          <a-menu-item @click="goToProfile">
            <a-icon type="user" /> <span>{{ $t("users.profile") }}</span>
          </a-menu-item>
          <a-menu-item @click="goToPreference">
            <a-icon type="setting" />
            <span>{{ $t("users.preferences.title") }}</span>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item @click="() => logout()">
            <a-icon style="margin-right: 8px;" type="poweroff" />
            <span> {{ $t("authentication.logout") }} </span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Menu, Button, Icon, Avatar, Dropdown } from "ant-design-vue";
import { router } from "../router";
import {
  isFPManager,
  isFPViewer,
  isUserManager,
  isSiteManager,
} from "../utils/userAuth";

Vue.use(Menu);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Dropdown);
Vue.use(Avatar);

export default {
  name: "header-nemu",
  methods: {
    goToProfile() {
      this.$router.push("/profile");
    },
    goToPreference() {
      this.$router.push("/preferences");
    },
    logout() {
      this.$store.commit("DELETE_USER_PREFERENCES")
      this.$store.dispatch("logout").then(() => {
        router.push("/login");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header-menu {
  background-color: #124c5a;
  float: right;
}

.ant-menu-item {
  color: #ffffff !important;
}

.ant-menu-horizontal > .ant-menu-item > a::before,
.ant-menu-item > a::before {
  color: #ffffff !important;
}

.header-menu-left {
  line-height: 60px;
  background-color: #124c5a;
  width: 600px;
}

.header-avatar {
  display: block;
  .avatar,
  .name {
    align-self: center;
  }
  .avatar {
    margin-right: 8px;
  }
  .name {
    font: normal 20px "Lato Bold";
    color: #ffffff !important;
  }
}
.avatar-menu {
  width: 150px;
}
.admin-header-right {
  margin-top: -2px;
  margin-right: 2%;
  background-color: #124c5a;
  float: right;
  display: block;
  color: inherit;
  .header-item {
    color: inherit;
    padding: 0 12px;
    cursor: pointer;
    align-self: center;
    a {
      color: inherit;
      i {
        font-size: 16px;
      }
    }
  }
}
</style>
