import store from "../store";
import { Role, RoleGroup } from './userRoles'

export function isFPManager() {
  let current = store.getters.getCurrentUserRoles();
  if (current) {
    return current.roles.includes(Role.FPManager);
  }
}

export function isFPViewer() {
  let current = store.getters.getCurrentUserRoles();
  if (current) {
    return current.roles.includes(Role.FPViewer);
  }
}

export function isUserManager() {
  let current = store.getters.getCurrentUserRoles();
  if (current) {
    return current.roles.includes(Role.UserManager);
  }
}

export function isSiteManager() {
  let current = store.getters.getCurrentUserRoles();
  if (current) {
    return current.roles.includes(Role.SiteManager);
  }
}

export function userRoleGroup(roles) {
  roles = roles.map((r) => {
    return r.name;
  });
  if (
    JSON.stringify(roles) ==
    JSON.stringify([Role.UserManager, Role.SiteManager])
  ) {
    return RoleGroup.Admin;
  } else if (
    JSON.stringify(roles) == JSON.stringify([Role.FPManager, Role.FPViewer])
  ) {
    return RoleGroup.Telepilot;
  } else {
    return RoleGroup.Integrator;
  }
}
