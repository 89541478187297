import { droneTypes } from "../mutation-types";
import axios from "../plugin/axios";
import { addApiPrefix, getErrorMsg, header } from "../helpers";
import { updateBaseObj, addBaseObj, deleteBaseObj } from "./bases";

const state = {};
const getters = {};

const mutations = {
  [droneTypes.ADD_DRONE](state, { base, drone }) {
    addBaseObj(base, drone, "drones");
  },
  [droneTypes.UPDATE_DRONE](state, { base, drone }) {
    updateBaseObj(base, drone, "drones");
  },
  [droneTypes.DELETE_DRONE](state, { base, drone }) {
    deleteBaseObj(base, drone, "drones");
  },
};

const actions = {
  updateDrone({ commit }, { base, drone }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          addApiPrefix(`/bases/${base.id}/drones/${drone.id}`),
          drone,
          header()
        )
        .then((response) => {
          drone = response.data.payload.drone;
          commit(droneTypes.UPDATE_DRONE, { base, drone });
          resolve();
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
    });
  },
  addDrone({ commit }, { base, drone }) {
    return new Promise((resolve, reject) => {
      drone = Object.assign({}, drone);
      delete drone.id;
      axios
        .post(addApiPrefix(`/bases/${base.id}/drones`), drone, header())
        .then((response) => {
          drone = response.data.payload.drone;
          commit(droneTypes.ADD_DRONE, { base, drone });
          resolve();
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
    });
  },
  deleteDrone({ commit }, { base, drone }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          addApiPrefix(`/bases/${base.id}/drones/${drone.id}`),
          header()
        )
        .then(() => {
          commit(droneTypes.DELETE_DRONE, { base, drone });
          resolve();
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
