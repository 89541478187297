import { userTypes } from "../mutation-types";
import axios from "../plugin/axios";
import store from "..";
import { addApiPrefix, getErrorMsg, header } from "../helpers";

const state = {
  users: [],
  lastFetch: null,
  currentUser: null,
  lastCurrentUserFetch: null,
};

const getters = {
  getCurrentUser: (state) => () => {
    return state.currentUser;
  },
};

const mutations = {
  [userTypes.FETCH_USERS](state, users) {
    state.users = [...users];
    state.lastFetch = new Date();
  },
  [userTypes.UPDATE_USER](state, user) {
    if (state.users.length !== 0) {
      let userIdx = state.users.findIndex((u) => u.id === user.id);

      if (userIdx !== -1) {
        state.users[userIdx] = Object.assign({}, user);
      }
    }

    if (state.currentUser !== null && user.id === state.currentUser.id) {
      state.currentUser = Object.assign({}, user);
    }
  },
  [userTypes.ADD_USER](state, user) {
    state.users.push(user);
  },
  [userTypes.DELETE_USER](state, user) {
    let userIdx = state.users.findIndex((u) => u.id === user.id);

    if (userIdx !== -1) {
      state.users.splice(userIdx, 1);
    }
  },
  [userTypes.SET_CURRENT_USER](state, user) {
    state.currentUser = user;
    state.lastCurrentUserFetch = new Date();
  },
};

const actions = {
  updateUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios
        .put(addApiPrefix(`/users/${user.id}`), user, header())
        .then((resp) => {
          user = resp.data.payload.user;
          commit(userTypes.UPDATE_USER, user);
          resolve();
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
    });
  },
  updateToken({ commit }) {
    const refreshToken = store.getters.getRefreshToken();
    return axios({
      method: 'post',
      url: addApiPrefix("/users/refresh"),
      headers: { 'Authorization': `Bearer ${refreshToken}` }
    })
    .then((resp) => {
      if (resp.status === 200) {
        const accessToken = resp.data.payload?.accessToken;
        const refreshToken = resp.data.payload?.refreshToken;
        if (accessToken) {
          store.dispatch("setAccessToken", accessToken);
          store.dispatch("setRefreshToken", refreshToken);
        }
      }
    })
  },
  addUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      delete user.id;
      axios
        .post(addApiPrefix(`/users`), user, header())
        .then((resp) => {
          user = resp.data.payload.user;
          commit(userTypes.ADD_USER, user);
          resolve();
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
    });
  },
  deleteUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios
        .delete(addApiPrefix(`/users/${user.id}`), header())
        .then(() => {
          commit(userTypes.DELETE_USER, user);
          resolve();
        })
        .catch((error) => {
          reject(Error(getErrorMsg(error)));
        });
    });
  },
  fetchUsers({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(addApiPrefix("/users"), header())
        .then((resp) => {
          commit(userTypes.FETCH_USERS, resp.data.payload.users);
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  setCurrentUser({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(addApiPrefix('/users/current'), header())
        .then((resp) => {
          commit(userTypes.SET_CURRENT_USER, resp.data.payload.user);
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
