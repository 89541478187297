import {SET_LAST_HTTP_ERROR} from '../mutation-types';

const serverIp = process.env.VUE_APP_SERVER_IP || ''
const httpPort = process.env.VUE_APP_HTTP_PORT || location.port

const portString = (httpPort === '') ? '' : `:${httpPort}`

const state = {
  config: {
    httpPrefix: (serverIp === '') ? `${location.protocol}//${location.hostname}${portString}/` : `${location.protocol}//${serverIp}${portString}/`,
    apiPrefix: (serverIp === '') ? `${location.protocol}//${location.hostname}${portString}/v1` : `${location.protocol}//${serverIp}${portString}/v1`,
    googleApiKey: 'AIzaSyDLlfwGDzUrXSTpLVslMaLcFY_CCuu10Yg'
  },
  lastHTTPError: {
    status: null,
    message: null
  }
}

const mutations = {
  [SET_LAST_HTTP_ERROR](state, {status, message}) {
    state.lastHTTPError = {...{status, message}};
  }
} 

const actions= {
  setLastHTTPError(store, {status, message}) {
    store.commit(SET_LAST_HTTP_ERROR, {status, message});
  }
}

export default {
  state,
  mutations,
  actions
}