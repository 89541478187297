import axios from "../plugin/axios";
import { addApiPrefix, getErrorMsg, header } from "../helpers";
import {
  updateFlightplanObj,
  addFlightplanObj,
  deleteFlightplanObj,
} from "./flightPlans";

import { missionMapTypes } from "../mutation-types";

const state = {};

const getters = {};

const mutations = {
  [missionMapTypes.UPDATE_MISSION_MAP](state, { flightPlan, missionMap }) {
    updateFlightplanObj(flightPlan, missionMap, "missionMaps");
  },
  [missionMapTypes.ADD_MISSION_MAP](state, { flightPlan, missionMap }) {
    addFlightplanObj(flightPlan, missionMap, "missionMaps");
  },
  [missionMapTypes.DELETE_MISSION_MAP](state, { flightPlan, missionMap }) {
    deleteFlightplanObj(flightPlan, missionMap, "missionMaps");
  },
};

const actions = {
  updateMissionMap({ commit }, { flightPlan, missionMap }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          addApiPrefix(
            `/flightplans/${flightPlan.id}/missionmaps/${missionMap.id}`
          ),
          missionMap,
          header()
        )
        .then((resp) => {
          missionMap = resp.data.payload.missionMap;
          commit(missionMapTypes.UPDATE_MISSION_MAP, {
            flightPlan,
            missionMap,
          });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  addMissionMap({ commit }, { flightPlan, missionMap }) {
    return new Promise((resolve, reject) => {
      missionMap = Object.assign({}, missionMap);
      delete missionMap.id;

      axios
        .post(
          addApiPrefix(`/flightplans/${flightPlan.id}/missionmaps`),
          missionMap,
          header()
        )
        .then((resp) => {
          missionMap = resp.data.payload.missionMap;
          commit(missionMapTypes.ADD_MISSION_MAP, { flightPlan, missionMap });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  deleteMissionMap({ commit }, { flightPlan, missionMap }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          addApiPrefix(
            `/flightplans/${flightPlan.id}/missionmaps/${missionMap.id}`
          ),
          header()
        )
        .then(() => {
          commit(missionMapTypes.DELETE_MISSION_MAP, {
            flightPlan,
            missionMap,
          });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
