const FlightplanSvg = {
  template: `
  <svg 
    x="0px" 
    y="0px" 
    viewBox="0 0 480 480" 
    style="enable-background:new 0 0 480 480;" 
    xml:space="preserve"
  >
    <g>
      <g>
        <path d="M395.522,232v176h-48c-4.418,0-8,3.582-8,8v48h-296V16h352v104h16V8c0-4.418-3.582-8-8-8h-368c-4.418,0-8,3.582-8,8v464
          c0,4.418,3.582,8,8,8h312c2.121-0.005,4.154-0.847,5.656-2.344l56-56c0.669-0.68,1.211-1.474,1.6-2.344
          c0.112-0.248,0.176-0.488,0.264-0.744c0.23-0.672,0.37-1.371,0.416-2.08c0-0.168,0.096-0.32,0.096-0.488V232H395.522z
            M355.522,452.688V424h28.688L355.522,452.688z"/>
      </g>
    </g>
    <g>
    <g>
      <path d="M445.178,134.344c-9.877-9.442-25.435-9.442-35.312,0l-140,140c-1.5,1.5-2.344,3.534-2.344,5.656v24c0,4.418,3.582,8,8,8
        h24c2.122,0,4.156-0.844,5.656-2.344l140-140C454.912,159.898,454.912,144.102,445.178,134.344z M296.21,296h-12.688v-12.688
        l100-100L396.21,196L296.21,296z M407.522,184.688L394.834,172l8.688-8.688L416.21,176L407.522,184.688z M433.866,158.344
        l-6.344,6.344L414.834,152l6.344-6.344c3.55-3.391,9.138-3.391,12.688,0C437.354,149.166,437.354,154.834,433.866,158.344z"/>
    </g>
    </g>
    <g>
      <g>
        <path d="M99.522,104c-17.673,0-32,14.327-32,32s14.327,32,32,32s32-14.327,32-32S117.195,104,99.522,104z M99.522,152
          c-8.837,0-16-7.163-16-16s7.163-16,16-16s16,7.163,16,16S108.358,152,99.522,152z"/>
      </g>
    </g>
    <g>
      <g>
        <polygon points="249.178,37.656 237.866,26.344 219.522,44.688 201.178,26.344 189.866,37.656 208.21,56 189.866,74.344 
          201.178,85.656 219.522,67.312 237.866,85.656 249.178,74.344 230.834,56 		"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M323.522,96c-17.673,0-32,14.327-32,32s14.327,32,32,32c17.673,0,32-14.327,32-32S341.195,96,323.522,96z M323.522,144
          c-8.837,0-16-7.163-16-16s7.163-16,16-16s16,7.163,16,16S332.358,144,323.522,144z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M123.522,328c-17.673,0-32,14.327-32,32c0,17.673,14.327,32,32,32s32-14.327,32-32
          C155.522,342.327,141.195,328,123.522,328z M123.522,376c-8.837,0-16-7.163-16-16s7.163-16,16-16s16,7.163,16,16
          S132.358,376,123.522,376z"/>
      </g>
    </g>
    <g>
      <g>
        <polygon points="161.178,221.656 149.866,210.344 131.522,228.688 113.178,210.344 101.866,221.656 120.21,240 101.866,258.344 
          113.178,269.656 131.522,251.312 149.866,269.656 161.178,258.344 142.834,240 		"/>
      </g>
    </g>
    <g>
      <g>
        <polygon points="361.178,333.656 349.866,322.344 331.522,340.688 313.178,322.344 301.866,333.656 320.21,352 301.866,370.344 
          313.178,381.656 331.522,363.312 349.866,381.656 361.178,370.344 342.834,352 		"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M173.49,160h13.6v-16h-32c-4.418,0-8,3.582-8,8v32h16v-11.2c34.703,44.743,32.205,107.959-5.92,149.824l11.848,10.768
          C213.358,284.724,215.262,210.889,173.49,160z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M219.522,368c-17.673,0-32,14.327-32,32c0,17.673,14.327,32,32,32c17.673,0,32-14.327,32-32
          C251.522,382.327,237.195,368,219.522,368z M219.522,416c-8.837,0-16-7.163-16-16s7.163-16,16-16s16,7.163,16,16
          S228.358,416,219.522,416z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M273.178,114.344l-24-24l-11.312,11.312L248.21,112H147.522v16H248.21l-10.344,10.344l11.312,11.312l24-24
          C276.301,122.532,276.301,117.468,273.178,114.344z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M291.522,160h-32v16h12.96c-39.294,41.833-61.104,97.11-60.96,154.504V352h16v-21.496
          c-0.132-53.015,19.885-104.1,56-142.912V200h16v-32C299.522,163.582,295.94,160,291.522,160z"/>
      </g>
    </g>
    <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
  </svg>
  `,
};

const FlightplanIcon = {
  template: `
    <a-icon :component="FlightplanSvg" />
  `,
  data() {
    return {
      FlightplanSvg,
    };
  },
};

export default FlightplanIcon
